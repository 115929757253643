@import "~bootstrap/scss/functions";

$primary: #005f9b;
$light: #f6f6f6;
$dark: #2d2d2d;

$body-bg: $light;
$body-color: #666;

$enable-rounded: false;

$link-color: #000;
$link-decoration: none;
$link-hover-color: $primary;
$link-hover-decoration: none;

$text-muted: #666;

$headings-margin-bottom: 0;

@import "~bootstrap/scss/variables";

$range-title-color: #999;
$range-name-color: black;

$viewer-min-height: 30vh;
