@import '~flag-icon-css/sass/variables';
@import '~flag-icon-css/sass/flag-icons-base';

$countries: (
  'de',
  'es',
  'fr',
  'it'
);

@each $country in $countries {
  @include flag-icon($country);
}

.flag-icon-gb{
  background-image: url("./assets/flags/gb-us.png");
}
